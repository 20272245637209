<template>
  <div class="topbar">
    <ul class="topbar-buttons">
      <li><i class="fa-solid fa-bell"></i></li>
      <li @click="goToOptions()" ><i class="fa-solid fa-gear"></i></li>
      <li @click="$store.dispatch('logout')">
        <i class="fa-solid fa-power-off"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    goToOptions: function () {
        this.$router.push('/opciones')
    },
  }
};
</script>
