export default [
  {
    icon: "fa-solid fa-gauge-high",
    title: "Dashboard",
    to: "/dashboard",
    children: null,
  },
  {
    icon: "fa-solid fa-envelope",
    title: "Mensajeria",
    children: null,
    /*
    children: [
       {
        icon: "fa-solid fa-inbox",
        title: "Bandeja de Entrada",
        to: "/inbox",
        children: null,
      },
    ],*/
  },
   {
    icon: "fa-solid fa-clipboard",
    title: "Tramites",
    children: null,
    /*
    children: [
       {
        icon: "fa-solid fa-plus",
        title: "Solicitud Servicio",
        to: "/opciones",
        children: null,
      },
      {
        icon: "fa-solid fa-plus",
        title: "Registro de Nacimiento",
        to: "/opciones",
        children: null,
      },
      {
        icon: "fa-solid fa-plus",
        title: "Inscripción Cria",
        to: "/opciones",
        children: null,
      },
    ],*/
  },
  {
    icon: "fa-solid fa-trophy",
    title: "Torneos",
    children: [
      {
        icon: "fa-solid fa-plus",
        title: "Inscripción",
        to: "/inscripcion",
        children: null,
      },
      {
        icon: "fa-solid fa-medal",
        title: "Resultados",
        /*to: "/inscripcion",*/
        children: null,
      },
    ],
  },
  {
    icon: "fa-solid fa-suitcase-medical",
    title: "Examenes",
    children: null
    /*
    children: [
      {
        icon: "fa-solid fa-plus",
        title: "Inscripción",
        to: "/nuevaInscripcion",
        children: null,
      },
      {
        icon: " fa-sharp fa-solid fa-file-prescription",
        title: "Resultados",
        to: "/nuevaInscripcion",
        children: null,
      },
    ],*/
  },
];
