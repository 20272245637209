<template>
  <template v-if="!personaID && logeado">
    <div class="dashboard-layout">
      <SidebarNoSub />
      <div class="main">
        <TopbarNoSub />
        <div class="content-wrapper">
          <div class="content">
            <SolicitudSocio/>
          </div>
          <div class="footer">
            Copyright &reg; {{ new Date().getFullYear() }} - Gavion CMS - Powered
            by
            <a href="http://trememote.com.ar" target="_blank">&nbsp;Trememote</a>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="dashboard-layout">
      <Sidebar />
      <div class="main">
        <Topbar />
        <div class="content-wrapper">
          <div class="content">
            <router-view></router-view>
          </div>
          <div class="footer">
            Copyright &reg; {{ new Date().getFullYear() }} - Gavion CMS - Powered
            by
            <a href="http://trememote.com.ar" target="_blank">&nbsp;Trememote</a>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Topbar from "@/components/Topbar.vue";
import SidebarNoSub from "./Suscripcion/SidebarNoSub.vue";
import TopbarNoSub from "./Suscripcion/TopbarNoSub.vue";
import SolicitudSocio from "./Suscripcion/SolicitudSocio.vue";

export default {
  components: {
    Sidebar,
    Topbar,
    SolicitudSocio,
    SidebarNoSub,
    TopbarNoSub,
  },
   computed: {
    personaID(){
      if (this.$store.getters.user?.persona_id ){return true}
      return false
    },
    logeado(){
      if (this.$store.getters?.isLoggedIn){return true}
      return false
    },
   },
  beforeMount() {
    this.$store.dispatch("setCredentials");
    const isLoggedIn = this.$store.getters.isLoggedIn;

    if (!isLoggedIn) {
      this.$router.push("/login");
    }
    // } else {
    //   // this.$store.dispatch("getUnreadNotifications");
    // }
  },
};
</script>
