<template>
  <Widget class="h-auto">
    <template #title>Solicitud de Inscripcion a POA</template>
      <form @submit.prevent="enviarSolicitud">
        <FormText
          label="Nombre"
          icon="fa-solid fa-user"
          v-model="form.first_name"
          disabled
        />
        <FormText
          label="Apellido"
          icon="fa-solid fa-user"
          v-model="form.last_name"
          disabled
        />
        <FormSelect
              label="Ser Socio"
              icon="fa-solid fa-address-card"
              :options="socioOpcion"
              v-model="form.socio"
            />
        <FormText
          :placeholderText= placeholderCartaPresetacion
          label="Carta de Presentacion"
          icon="fa-solid fa-paragraph"
          v-model="cartaPresentacion"
          :textarea="true"
          />
      </form>
    <div v-if="cartaPresentacion && cartaPresentacion.length > 3" class="d-flex flex-row-reverse"><button class="btn btn-primary" type="submit" @click="enviarSolicitud"><i class="fa-solid fa-paper-plane"></i> Enviar</button></div>
    <div v-else class="d-flex flex-row-reverse"><button class="btn btn-primary disabled"><i class="fa-solid fa-paper-plane"></i> Enviar</button></div>
  </Widget>
</template>

<script>
import FormText from "@/components/Form/FormText.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import Widget from "@/components/Widget.vue";
export default {
  name: "SolicitudSocio",
  components:{
    FormSelect,
    FormText,
    Widget,
  },
  data: () =>({
    placeholderCartaPresetacion: 'Escribe una carta de presentacion',
    cartaPresentacion:null,
    form:{
      to: null,
      id: null,
      first_name: null,
      last_name: null,
      nombre:null,
      email: null,
      cartaPresentacion: null,
      socio: null,
    }
  }),
  computed:{
    socioOpcion(){
      return [{value: true, name: "Si"},{value: false, name: "No"}]
    }
  },
  mounted(){
    if (this.$store.getters.user){
      return this.form = {...this.$store.getters.user}
    }
  },
  methods:{
    enviarSolicitud(){
      this.form.nombre = `${this.form.first_name} ${this.form.last_name}`
      this.form.to = 'Aqui va el mail para alta';
      this.form.id = this.$store.getters.user.id
      
      console.log(this.form)
      if (this.form.to) {
        this.$toast.success("Respuesta Enviada con Exito");
        this.cartaPresentacion= null
        this.form.cartaPresentacion = null
        this.form.socio = null
      }else{
        this.$toast.error("No se pudo responder, pongase en contacto con el administrador")
      }
    }
  }
};
</script>

<style>
textarea {
    resize: none !important;
}
</style>