<template>
  <div class="sidebarNoSub">
    <div class="brandNoSub">
      <img src="/img/logo.svg" class="brandNoSub-logo" alt="" />
    </div>
    <!-- <h1 class="text-center"> </h1>
    <h1 class="text-center">M</h1>
    <h1 class="text-center">I</h1>
    <h1 class="text-center"> </h1>
    <h1 class="text-center"> </h1>
    <h1 class="text-center"> </h1>
    <h1 class="text-center"> </h1>
    <h1 class="text-center">P</h1>
    <h1 class="text-center">O</h1>
    <h1 class="text-center">A</h1> -->
  </div>
</template>

<style>
.sidebarNoSub {
    width: var(--sidebar-closed-width);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 3;
    box-shadow: var(--shadow);
    -moz-box-shadow: var(--shadow);
    -webkit-box-shadow: var(--shadow);
    transition: 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
.brandNoSub {
      display: flex;
      align-items: center;
      padding: 1em;
      background: var(--color-backgroundAll22);
      border-bottom: 1px solid var(--color-medium-tint);
    }
  img.brandNoSub-logo {
  max-width: 100%;
}
</style>
