<template>
  <div class="topbar">
    <ul class="topbar-buttons">
      <li @click="cerrarSession">
        <i class="fa-solid fa-power-off"></i>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  methods:{
    cerrarSession(){
      this.$store.dispatch('logout')
    }
  }
}
</script>
